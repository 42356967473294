.wh-video__innerframe
{
  position:relative;
  width:100%;
  cursor: pointer;
  z-index: 0;
}
.wh-video__innerframe::after
{
  display:block;
  content: '';
}
.wh-video.wh-video--aspect_16_9 .wh-video__innerframe::after
{
  padding-top:56.25%;
}
.wh-video.wh-video--aspect_4_3 .wh-video__innerframe::after
{
  padding-top:75%;
}

  .wh-video__innerframe__preview
, .wh-video__innerframe__preview > picture
{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow:hidden;
}
.wh-video__innerframe__preview > picture
{
  display:block;
}
.wh-video__innerframe__preview > picture > img
{
  /* for best results when the img isn't actually in the right scale, cover the available area */
  width:100%;
  height:100%;
  object-fit:cover;
}

.wh-video iframe
{
  position:absolute;
  width:100%;
  height:100%;
}

/* CSS rules for v1 video widgets (they have no innerframe but use data-video instead of data-wh-video) */
.wh-video[data-video]
{
  position:relative;
  width:100%;
  cursor: pointer;
  z-index: 0;
}
.wh-video[data-video].wh-video--aspect_4_3:after
{
  padding-top: 75%;
}
.wh-video[data-video].wh-video--aspect_16_9:after
{
  padding-top: 56.25%;
}
.wh-video[data-video]:after
{
  display: block;
  content: "";
}
