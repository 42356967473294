#results .buttons.hide
{
  margin: 0;
  height: 15px;
  visibility: hidden;
}

/* Overzicht van opgegeven antwoorden */
#results .questionsheader
{
  width: 100%;
}
#answerscontainer
{
  position: relative;
}
#answers
{
  margin: 0 5px;
}



.effecttool__collectedanswers
{
  margin: 0 0 20px 0;
}
.effecttool__collectedanswers .answer
{
  display: block;
}
 .effecttool__collectedanswers .answer
,#answers .againbutton
{
  width: 99%;
}
 .effecttool__collectedanswers .answer .title
,.effecttool__collectedanswers .answer .answerlink
{
  display: inline-block;
}
.effecttool__collectedanswers .answer .title
{
  min-width: 40%;
  max-width: 60%;
}
.effecttool__collectedanswers .answer .mobileseparator
{
  display: none;
}
.effecttool__collectedanswers .answer .answerlink
{
  margin: 6px 0 0 0;
  vertical-align: top;
  max-width: 750px;
}

#answers .againbutton
{
  text-align: right;
  margin-bottom: 18px;
}
#answers .againbutton .button
{
  background-color: #b2b2b2;
  color: #ffffff;
  padding: 12px 30px;
  font-size: 13px;
  text-decoration: none;
}
.effecttool__collectedanswers .answer .editimage
{
  position: relative;
  top: 3px;
  left: 5px;
  cursor: pointer;
}
