
.reminder /* de reminder popup activeer link */
{
  text-decoration: none;
  margin-left: 100px;
  cursor: pointer;
}

.reminder:before
{
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../web/img/icon-calendar.png");
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}


.reminderpopup
{
  display: none;
}
.wh-popup-body .reminderpopup
{
  display: block;
}

/* when used inline instead (for mobile) instead of popup */
.effect #reminderpopup
{
  background-color: #F0F0F0;
  padding: 10px;
  margin: 0 20px 20px 100px;
}


/* use .reminderpopup class instead of ID
   so partner CSS can easily override our CSS (due to specificy rules)
*/

/*  #getreminderform .header*/
.reminderpopup .header
{
  font-weight: bold;
  margin-bottom: 15px;
}
  .reminderpopup .preview
, .reminderpopup .datesettings
, .reminderpopup .calendarlinks
, .reminderpopup hr
{
  margin-bottom: 25px;
}

.reminderpopup .reminder-preview
{
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 15px 0;

  margin: 25px 0;
}
.reminderpopup .reminder-title
{
  /* reset other .title stuff */
  font: inherit;
  padding: 0;

  font-weight: bold;
  margin: 0 0 10px 0;
}
.reminderpopup .reminder-description
{
  margin: 0;
}
.reminderpopup .reminder-link
{
  display: inline-block;
  margin: 10px 0 0 0;
}

.reminderpopup .datesettings label
{
  display: inline-block;
  white-space: nowrap;

}

.reminderpopup .datesettings label + label
{
  margin-left: 30px;
}

.reminderpopup .datesettings .wh-datepicker
{
  width: 160px;
}

.reminderpopup .calendarlinks label
{
  display: block;
}
.reminderpopup .calendarlinks label + label
{
  margin-top: 10px;
}

.effect .reminderpopup hr
{
  background-color: #444444;
}

.reminderpopup hr
{
  height: 1px;
  background-color: #daf0e4;
  border: 0;
}

.reminderpopup .buttons
{
  overflow: hidden; /* grow to fit buttons */
}

.reminderpopup .buttons .nextbutton.disabled
{
  opacity: 0.5;
}

  #reminderhours
, #reminderminutes
{
  display: inline-block;
  width: 50px;
}
